@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  border-collapse: collapse;
  font-family: Roboto;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}


table {
  width: 60%;
  margin-right: 20px;
  border: 1px solid gray;
}

th,
tr,
td {
  border: 1px solid gray;
  border-collapse: collapse;
  padding: 5px 10px;
}

th {
  color: white;
  background-color: orange;
}

.selected-row {
  background-color: rgb(255, 255, 187);
}

.postDetails {
  width: 35%;
  border: 1px solid gray;
  padding: 5px;
  margin-top: 0;
  background-color: lightyellow;
}

@media (max-width: 768px) {

  .container {
    flex-direction: column;
  }

  table,
  .postDetails {
    width: 100%;
  }
}